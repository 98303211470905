import * as React from 'react';
import './footer.scss';
import Button from '@material-ui/core/Button';
import { Container } from '../Container';
import { AdapterLink } from '../AdapterLink';
export const Footer: React.FC = () => {
  return (
    <footer className="footer d-none d-lg-block" style={{ zIndex: 4 }}>
      <Container>
      <div>
        <Button
              variant="contained"
              color="primary"
              component={AdapterLink}
              to="/terms"
            >
              Terms & Conditions
        </Button>
        <Button
              variant="contained"
              color="primary"
              component={AdapterLink}
              to="/privacy"
            >
              Privacy Policy
        </Button>
        </div>
      </Container>
    </footer>
  );
};
