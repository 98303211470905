import * as React from 'react';
import '../Chat/app.scss';
import { Header } from '../Header';
import { Footer } from '../Footer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Container } from '../Container';
import SimpleSnackbar from '../SimpleSnackbar';
import { AuthContext } from '../../contexts/AuthContext';

interface IValueState {
  firstName: string;
  lastName: string;
  email: string;
  lawFirm: string;
  jobTitle: string;
  streetAddress:string;
  officePhone: string;

  errorMessages: string[];

  firstNameHasError: boolean;
  lastNameHasError: boolean;
  emailHasError: boolean;
  lawFirmHasError: boolean;
  jobTitleHasError: boolean;
  streetAddressHasError: boolean;
  officePhoneHasError: boolean;
}

export const EditProfile: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const userData = auth.user['attributes'];
  
  const [values, setValues] = React.useState<IValueState>({
    firstName: userData['custom:firstName'],
    lastName: userData['custom:lastName'],
    email: userData['email'],
    lawFirm: userData['custom:lawFirm'],
    jobTitle: userData['custom:jobTitle'],
    streetAddress: userData['custom:streetAddress'],
    officePhone: userData['custom:officePhone'],

    errorMessages: [],

    firstNameHasError: false,
    lastNameHasError: false,
    emailHasError: false,
    lawFirmHasError: false,
    jobTitleHasError: false,
    streetAddressHasError: false,
    officePhoneHasError: false
  });

  const validateForm = (firstName: string, lastName: string, email: string, lawFirm: string, jobTitle: string, streetAddress: string, officePhone: string) => {
    let err: string = '';
    if (!firstName && !lastName && !email && !lawFirm && !jobTitle && !streetAddress && !officePhone ) {
      err = 'Please enter all fields.';
    }
    if (!firstName && lastName && email && lawFirm && jobTitle && streetAddress && officePhone ) {
      err = 'Please enter a firstName.';
    }
    if (firstName && !lastName && email && lawFirm && jobTitle && streetAddress && officePhone ) {
      err = 'Please enter a lastName.';
    }
    if (firstName && lastName && !email && lawFirm && jobTitle && streetAddress && officePhone ) {
      err = 'Please enter an email.';
    }
    if (firstName && lastName && email && !lawFirm && jobTitle && streetAddress && officePhone ) {
      err = 'Please enter law firm.';
    }
    if (firstName && lastName && email && lawFirm && !jobTitle && streetAddress && officePhone ) {
      err = 'Please enter job title.';
    }
    if (firstName && lastName && email && lawFirm && jobTitle && !streetAddress && officePhone ) {
      err = 'Please enter street address.';
    }if (firstName && lastName && email && lawFirm && jobTitle && streetAddress && !officePhone ) {
      err = 'Please enter office phone.';
    }
    setValues({
      ...values,
      errorMessages: [...values.errorMessages, err],
      firstNameHasError: !firstName,
      lastNameHasError: !lastName,
      emailHasError: !email,
      lawFirmHasError: !lawFirm,
      jobTitleHasError: !jobTitle,
      streetAddressHasError: !streetAddress,
      officePhoneHasError: !officePhone
    });

    return !!firstName && !!lastName && !!email && !!lawFirm && !!jobTitle && !!streetAddress && !!officePhone;
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {  // if "Enter"
      e.preventDefault();
      handleUpdate();
    }
  };

  const handleUpdate = async () => {
    try {
      const {firstName, lastName, email, lawFirm, jobTitle, streetAddress, officePhone} = values;
      const inputIsValid = validateForm(firstName, lastName, email, lawFirm, jobTitle, streetAddress, officePhone);

      if (inputIsValid) {
        setValues({
          ...values,
          errorMessages: [],
          firstNameHasError: false,
          lastNameHasError: false,
          emailHasError: false,
          lawFirmHasError: false,
          jobTitleHasError: false,
          streetAddressHasError: false,
          officePhoneHasError: false
        });

        auth.updateUserAccount && (await auth.updateUserAccount({firstName, lastName, email, lawFirm, jobTitle, streetAddress, officePhone}));
        setValues({
          ...values,
          errorMessages: ['User profile updated successfully.'],
        });

        window.location.href = "/";

      }
    } catch (error) {
      setValues({
        ...values,
        errorMessages: [error.message],
      });
    }
  };

  return (
    <div>
	    <Header/>
	   	<div className="app-main">
        <div className="app-main-inner">
  			  <Container
  			    style={{
  			      paddingTop: 30,
  			      paddingBottom: 30,
  			      flex: 1,
  			    }}
  			  >
  		   	  <h1 className="h2 border-custom">Edit Profile</h1>
            <div className="editprofile">
              <form noValidate  autoComplete="off" onKeyDown={inputOnKeyDown}>
                {values.errorMessages &&
                values.errorMessages.map(
                  (err, i) => !!err && <SimpleSnackbar key={i} message={err} />,
                )}
                <h3>First Name</h3>
                <TextField
                  error={values.firstNameHasError}
                  id="standard-firstName"
                  placeholder="First Name"
                  type="text"
                  value={values.firstName}
                  onChange={e => {
                    setValues({ ...values, firstName: e.target.value });
                  }}
                />
                <h3>Last Name</h3>
                <TextField
                  error={values.lastNameHasError}
                  id="standard-lastName"
                  placeholder="Last Name"
                  type="text"
                  value={values.lastName}
                  onChange={e => {
                    setValues({ ...values, lastName: e.target.value });
                  }}
                />
                <h3>Email</h3>
                <TextField
                  error={values.emailHasError}
                  id="standard-email"
                  placeholder="Email Address"
                  type="email"
                  value={values.email}
                  onChange={e => {
                    setValues({ ...values, email: e.target.value });
                  }}
                />
                <h3>Law Firm</h3>
                <TextField
                  error={values.lawFirmHasError}
                  id="standard-lawFirm"
                  placeholder="Law Firm"
                  type="text"
                  value={values.lawFirm}
                  onChange={e => {
                    setValues({ ...values, lawFirm: e.target.value });
                  }}
                />
                <h3>Job Title</h3>
                 <TextField
                    error={values.jobTitleHasError}
                    id="standard-jobTitle"
                    placeholder="Job Title"
                    type="text"
                    value={values.jobTitle}
                    onChange={e => {
                      setValues({ ...values, jobTitle: e.target.value });
                    }}
                  />
                <h3>Office Address</h3>
                 <TextField
                    error={values.streetAddressHasError}
                    id="standard-streetAddress"
                    placeholder="Address"
                    type="text"
                    value={values.streetAddress}
                    onChange={e => {
                      setValues({ ...values, streetAddress: e.target.value });
                    }}
                  />
                <h3>Office Phone</h3>
                 <TextField
                    error={values.officePhoneHasError}
                    id="standard-officePhone"
                    placeholder="Office Phone"
                    type="text"
                    value={values.officePhone}
                    onChange={e => {
                      setValues({ ...values, officePhone: e.target.value });
                    }}
                  />
                  <div className="btn p-0">
                    <Button
                      onClick={handleUpdate}
                      variant="contained"
                      className="update"
                    >
                      Update
                    </Button>
                  </div>
                </form>
              </div>
            </Container>
          </div>
		    </div>
		  <Footer/>
    </div>
  );
};
