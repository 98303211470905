import React from 'react';
import './message-list.scss';

interface IMessageList {
  messages: IMessage[];
  link: string | null;
  intent: String;
  parameters: any;
}

export interface IMessage {
  sentBy: 'PERSON' | 'BOT';
  createdAt: Date;
  message: string;
}

export const MessageList: React.FC<IMessageList> = ({ messages, link, intent, parameters }) => {
  let scrollContainer = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    scrollContainer.current &&
      scrollContainer.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <>
      <ul className="messageList">
        {messages.map((val, index) => {
          if(intent == 'projects/lawdroid-voice/agent/intents/8edeb816-fa06-4548-9532-34777ed9ad90' && parameters[index] && parameters[index].fields && parameters[index].fields.CreateOrUpdate && parameters[index].fields.CreateOrUpdate.stringValue && parameters[index].fields.CreateOrUpdate.stringValue == 'Create' && parameters[index].fields.Subject && parameters[index].fields.Subject.stringValue && parameters[index].fields.Detail && parameters[index].fields.Detail.stringValue && parameters[index].fields.ResourceReference && (parameters[index].fields.ResourceReference.numberValue || parameters[index].fields.ResourceReference.stringValue) && parameters[index].fields.Confirmation.stringValue == ''){
            val.message = `I have prepared a note titled ${parameters[index].fields.Subject.stringValue} with the details ${parameters[index].fields.Detail.stringValue}. Do you want me to create it?`
          }
          return (
            <li className={`message is-${val.sentBy}`} key={index} dangerouslySetInnerHTML={{ __html: val.message }}></li>
          )
        })}
        {link ? (
          <li className="message is-BOT">
            <a href={link} target="_blank">
              Download PDF
            </a>
          </li>
        ) : null}
      </ul>
      <div className="messageList-spacer" ref={scrollContainer} />
    </>
  );
};
