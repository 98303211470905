import * as React from 'react';
import '../Chat/app.scss';

import { Header } from '../Header';
import { Footer } from '../Footer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { AdapterLink } from '../AdapterLink';
import { Container } from '../Container';
import { AuthContext } from '../../contexts/AuthContext';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import SimpleSnackbar from '../SimpleSnackbar';

interface IValueState {
  oldpwd: string;
  newpwd: string;
  errorMessages: string[];
  oldpwdHasError: boolean;
  newpwdHasError: boolean;
}

export const Profile: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const userData = auth.user['attributes'];
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [values, setValues] = React.useState<IValueState>({
    oldpwd: '',
	newpwd: '',
	errorMessages: [],
	oldpwdHasError: false,
	newpwdHasError: false
  });

  const validateForm = (oldpwd: string, newpwd: string) => {
    let err: string = '';
    if (!oldpwd && !newpwd) {
      err = 'Please enter old and new password.';
    }
    if (!oldpwd && newpwd) {
      err = 'Please enter old password.';
    }
    if (oldpwd && !newpwd) {
      err = 'Please enter new password.';
    }

    setValues({
      ...values,
      errorMessages: [...values.errorMessages, err],
      oldpwdHasError: !oldpwd,
      newpwdHasError: !newpwd,
    });

    return !!oldpwd && !!newpwd;
  };

  const handleChangePwd = async () => {
    try {
      const { oldpwd, newpwd } = values;
      const inputIsValid = validateForm(oldpwd, newpwd);

      if (inputIsValid) {
        setValues({
          ...values,
          errorMessages: [],
          oldpwdHasError: false,
          newpwdHasError: false,
        });

        auth.changePassword && (await auth.changePassword({ oldpwd, newpwd }));
        setValues({
          ...values,
          errorMessages: ['Password updated successfully.'],
        });
        handleClose();

      }
    } catch (error) {
      setValues({
        ...values,
        errorMessages: [error.message],
      });
    }
  };

  return (
    <div>
	    <Header/>
	   	<div className="app-main">
			<div className="app-main-inner">
			  <Container
			    style={{
			      paddingTop: 40,
			      paddingBottom: 30,
			      flex: 1,
			    }}
			  >
			  <div className="row py-1 profile-options border-custom">
			  	<div className="col p-0">
			  		<h1 className="h2 p-0">Profile</h1>
			  	</div>
			  	<div className="col d-lg-block d-md-block d-sm-block d-none p-0 options text-right">
			  		<Button
		              component={AdapterLink}
		              to="/edit"
		            >
		              Edit Profile
		            </Button>
		            <Button
		              onClick={() => handleShow()}
		            >
		              Change Password
		            </Button>
			  	</div>
			  	<div className="col d-lg-none d-md-none d-sm-none d-block justify-content-end p-0 align-items-center text-right sm-profile">
					<Dropdown>
				    <Dropdown.Toggle  id="dropdown-custom-components" className="dropdown-toggle btn btn-light border-0 p-0">
				      <img src={require('../../assets/images/showmorebutton.png')} alt="Profile Options"/>
				    </Dropdown.Toggle>

				    <Dropdown.Menu >
				      <Dropdown.Item href="/edit">Edit Profile</Dropdown.Item>
					  <Dropdown.Item href="#" onClick={() => handleShow()}>Change Password</Dropdown.Item>
				    </Dropdown.Menu>
				  </Dropdown>
			  	</div>
			  </div>
			  <div className="user-info">
			  	<div className="row border-0 p-0">
				  	<div className="col edit">
				        <Modal show={show} onHide={handleClose} className="pwd">
					        <Modal.Header closeButton>
					          <Modal.Title>Change Password</Modal.Title>
					        </Modal.Header>
					        <Modal.Body>
					        <form autoComplete="off" noValidate className="newPasscode">
					        {values.errorMessages &&
				            values.errorMessages.map(
				              (err, i) => !!err && <SimpleSnackbar key={i} message={err} />,
				            )}
				               <TextField
				                  error={values.oldpwdHasError}
				                  id="standard-oldpwd"
				                  placeholder="Enter Old Password"
				                  type="password"
				                  value={values.oldpwd}
				                  onChange={e => {
				                    setValues({ ...values, oldpwd: e.target.value });
				                  }}
				                />
				                <TextField
				                  error={values.newpwdHasError}
				                  id="standard-newpwd"
				                  placeholder="Enter New Password"
				                  type="password"
				                  value={values.newpwd}
				                  onChange={e => {
				                    setValues({ ...values, newpwd: e.target.value });
				                  }}
				                />
				            </form>
					    	</Modal.Body>
					        <Modal.Footer>
					        <div className="row">
					        	<div className="col">
					        		<Button onClick={handleClose}>
							            Cancel
						          	</Button>
					        	</div>
					        	<div className="col">
					        		<Button onClick={handleChangePwd}>
							            Ok
						          	</Button>
					        	</div>
					        </div>
					        </Modal.Footer>
					      </Modal>
				  	</div>
				  </div>
			  	<div className="username d-none">
			  		<h3>{userData['custom:firstName']} {userData['custom:lastName']}</h3>
			  		<span>{userData['email']}</span>
			  	</div>
			  	<h3>User Information</h3>
			   	<div className="row spacing-border">
			   		<div className="col font-weight p-0">Law Firm</div>
			   		<div className="col info-value">{userData['custom:lawFirm']}</div>
			   	</div>
			   	<div className="row spacing-border">
			   		<div className="col font-weight p-0">Job Title</div>
			   		<div className="col info-value">{userData['custom:jobTitle']}</div>
			   	</div>
			   	<h3>Contact Information</h3>
			   	<div className="row spacing-border">
			   		<div className="col font-weight p-0">Address</div>
			   		<div className="col info-value">{userData['custom:streetAddress']}</div>
			   	</div>
			   	<div className="row spacing-border">
			   		<div className="col font-weight p-0">Office Phone</div>
			   		<div className="col info-value">{userData['custom:officePhone']}</div>
			   	</div>
			   	<div className="row spacing-border">
			   		<div className="col font-weight p-0">Email Address</div>
			   		<div className="col info-value">{userData['email']}</div>
			   	</div>
			   	<div className="row spacing-border">
			   		<div className="col font-weight p-0">Timezone</div>
			   		<div className="col info-value">{userData['custom:timeZone']}</div>
			   	</div>
			  </div>
			  </Container>
			</div>
		</div>
		<Footer/>
	</div>
  );
};
