import * as React from 'react';
import './chat-footer.scss';
import { Container } from '../Container';
import { Mic } from '../Mic';
import { IRenderProps } from '../../Containers/MessageContainer';
import { Button } from '../Button';
import Modal from 'react-bootstrap/Modal';

export const ChatFooter: React.FC<IRenderProps> = ({
  messages,
  setMessages,
  resetSession,
  msgUpdated,
  setMessageUpdated
}) => {

  const [inputVal, setInputVal] = React.useState<string>('');
  const [mic, setMic] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);

  const sendUserMessage = () => {
    // if (messages.data[messages.data.length - 1].sentBy == 'BOT' && msgUpdated.bufferLen != 0) {
      if(checkQuestion()){
        if (!validateDate(inputVal)) {
          setShow(true);
          return;
        }
      }
      localStorage.setItem('muteBot', 'true');
      setMessages({ ...messages, rawUserResponse: inputVal });
      setInputVal('');
    // }
  };

  const validateDate = (message) => {
    message = message.replace("-", ",").replace("-", ",");
    let dateArray: any = message.replace(/, /g, ",").replace(/,/g, " ").replace(/\//g, " ").split(" ");
    let year: any = dateArray[2];
    let month: any = dateArray[0];
    let day: any = dateArray[1];
    let monthnumnames: any = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']; 
    let monthnumnamesfull: any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    let fullMonthNames: any = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

    let shortMonthNames: any = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

    if(fullMonthNames.indexOf(month.toLowerCase()) > -1 || monthnumnames.indexOf(month) > -1 || monthnumnamesfull.indexOf(month) > -1 || shortMonthNames.indexOf(month.toLowerCase()) > -1){
      if(day && year && month){
        return true;
      }
    }
    return false;
  }

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {  // if "Enter"
      e.preventDefault();
      sendUserMessage();
    }
  };

  const checkQuestion = () => {
    if(messages.data[messages.data.length - 1].message.includes('&nbsp;')){
      return true;
    }
    return false;
  }

  return (
    <section className="chat-footer" style={{ zIndex: 3 }}>
      <Mic
        onResults={(rawUserResponse, messageUpdated) => {
          if(checkQuestion()){
            if (!validateDate(rawUserResponse)) {
              setShow(true);
              return;
            }
          }
          localStorage.setItem('muteBot', 'true');
          setMic(false);
          setMessages({ ...messages, rawUserResponse });
          setMessageUpdated({
            ...msgUpdated,
            messageUpdated: false
          });
        }}
        onMicChange={(status) => {
          setMic(status);
        }}
        messages={messages.data}
        parameters={messages.parameters}
        onBotResponse={(messages.data.length > 1 ? msgUpdated.messageUpdated : (messages.data.length === 1 ? msgUpdated.messageUpdated : false))}
        botMsgLen={msgUpdated.bufferLen}
      />
      <Container>
        <>
          <Modal show={show}>
            <Modal.Header closeButton style={{ backgroundColor: '#305cc5', color: '#fff' }}>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: 'inherit' }}>
              Please enter a valid date<br />
              Eg. mm dd yyyy or mm-dd-yyyy 
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn btn-primary text-white" onClick={() => { setShow(false) }}>
                Ok
                </Button>
            </Modal.Footer>
          </Modal>
            <div className="chat-footer-input text-center">
              <p className="text-white mb-0 text-center d-none">Click the microphone again to say..</p>
              <div className="">
                <input
                  type="text"
                  name="chat-footer-input"
                  className="chat-footer-input__input"
                  placeholder="Type your response"
                  onChange={ele => setInputVal(ele.target.value)}
                  value={inputVal}
                  onKeyDown={inputOnKeyDown}
                />
                <Button
                  label="Send"
                  className="chat-footer-input__button"
                  onClick={sendUserMessage}
                />
              </div>
            </div>
        </>
      </Container>
    </section>
  );
};
