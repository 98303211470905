import { ApiBase } from './ApiBase';

export interface MessageResponse {
  message?: string;
  error?: Error;
  data?: any;
}

export class DAClient extends ApiBase {
  private session: string = 'XM9J1Q66RVC3P1PW0ZEJVSYTVTCCPLQO';
  private secret: string = 'IeysPzsiafqgEtIY';
  private i: string = 'docassemble.playground1:docassemble-simple-test.yml';

  constructor() {
    super({ baseUrl: '/api' });
  }
  /**
   * Sets the Authorization header with the user's Clio Access Token
   * and sets the x-uat header with the Cognito Access Token for
   * situations where the Clio Access Token is expired and we
   * need to grab a new one with the Refresh Token.
   */
  private setAuthHeader() {
    return {
      headers: {
        'X-API-Key': 'PHRNJHJSULENJKBJAN0R3847Y3PY1SDV',
      },
    };
  }

  private setSession() {
    return {
      i: this.i,
      session: this.session,
      secret: this.secret,
    };
  }

  private urlParams(obj: {}) {
    return Object.entries(obj)
      .map(([key, val]) => `${key}=${encodeURIComponent(val.toString())}`)
      .join('&');
  }

  public async getQuestion(): Promise<any | Error> {
    const headers = this.setAuthHeader();
    const session = this.urlParams(this.setSession());
    const { data } = await this.get(`session/question?${session}`, {
      ...headers,
    });
    return data;
  }

  public async updateVariable(
    questionName: string,
    variables: {},
  ): Promise<any | Error> {
    const headers = this.setAuthHeader();
    const { data } = await this.post(
      `session`,
      {
        ...this.setSession(),
        variables,
        question_name: questionName,
      },
      {
        ...headers,
      },
    );
    return data;
  }

  public async newSession(): Promise<any> {
    const headers = this.setAuthHeader();
    const i = this.urlParams({ i: this.i });
    const { data } = await this.get(`session/new?${i}`, {
      ...headers,
    });

    this.secret = data.secret;
    this.session = data.session;
  }
}
