export const  CLIO_CLIENT_ID = 'rgAf7KfyiMKlwwS2S0PsSzMsLaWVallSqZDdQwNp';
export const CLIO_CLIENT_SECRET = 'Crqw1BCE90x7C4yzWzXQSQbKjGQu3X6VpBkrzAN4';

// export const BACKEND_API_URL = 'http://localhost:3000/';
export const BACKEND_API_URL = 'https://business-scorecard-backend.herokuapp.com/';

// export const API_URL = 'http://localhost:4200';
export const API_URL = 'https://voice.lawdroid.com';

// export const APP_API_URL = 'http://localhost:4200';
export const APP_API_URL = 'https://voice.lawdroid.com';
export const DATA_API_URL = 'https://voiceapi2.lawdroid.com';
// export const DATA_API_URL = 'http://localhost:3000';

export const CRYPTO_KEY = 'crypto!2018';
