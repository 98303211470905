import * as React from 'react';
import './app.scss';
import { MessageList } from '../MessageList';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { ChatFooter } from '../ChatFooter';

export const Chat: React.FC = () => {

  React.useEffect(() => {
    localStorage.removeItem('notes');
  }, []);

  return (
    <MessageContainer
      render={({ messages, setMessages, resetSession, msgUpdated, setMessageUpdated }) => (
        <>
          <Header />
          <div className="app-main" style={{ zIndex: 2 }}>
            <div className="app-main-inner">
              <Container
                style={{
                  paddingTop: 30,
                  paddingBottom: 30,
                  flex: 1,
                }}
              >
                <MessageList messages={messages.data} link={messages.link} intent={messages.intent} parameters={messages.parameters} />
              </Container>
            </div>
          </div>
          <ChatFooter
            messages={messages}
            setMessages={setMessages}
            resetSession={resetSession}
            msgUpdated={msgUpdated}
            setMessageUpdated={setMessageUpdated}
          />
          <Footer />
        </>
      )}
    />
  );
};
