import * as React from 'react';
import './login.scss';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthContext } from '../../contexts/AuthContext';
import { AdapterLink } from '../AdapterLink';

import SimpleSnackbar from '../SimpleSnackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    img_logo: {
      width: 350,
      marginBottom: 10,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 20,
    },
    menu: {
      width: 200,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: 20,
    },
    input: {
      display: 'none',
    },
  }),
);

interface IValueState {
  username: string;
  password: string;
  errorMessages: string[];
  usernameHasError: boolean;
  passwordHasError: boolean;
}

export const Login: React.FC<RouteComponentProps> = props => {
  const auth = React.useContext(AuthContext);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState<IValueState>({
    username: '',
    password: '',
    usernameHasError: false,
    passwordHasError: false,
    errorMessages: [],
  });

  const { from } = props.location.state || { from: { pathname: '/' } };

  if (auth.hasValidSession && !auth.requireVerification) {
    return <Redirect to={from} />;
  }

  /*if (auth.requireVerification && !auth.hasValidSession) {
    return <Redirect to={'/verify'} />;
  }*/

  const validateForm = (username: string, password: string) => {
    let err: string = '';
    if (!username && !password) {
      err = 'Please enter email and password.';
    }
    if (!username && password) {
      err = 'Please enter email.';
    }
    if (username && !password) {
      err = 'Please enter password.';
    }

    setValues({
      ...values,
      errorMessages: [...values.errorMessages, err],
      usernameHasError: !username,
      passwordHasError: !password,
    });

    return !!username && !!password;
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {  // if "Enter"
      e.preventDefault();
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (loading) {
      return
    }
    setLoading(true);
    try {
      const { username, password } = values;
      const inputIsValid = validateForm(username, password);

      if (inputIsValid) {
        setValues({
          ...values,
          errorMessages: [],
          usernameHasError: false,
          passwordHasError: false,
        });

        auth.login && (await auth.login({ username, password }));
        setLoading(false);
        props.history.push('/');
      }
    } catch (error) {
      setLoading(false);
      setValues({
        ...values,
        errorMessages: [error.message],
      });
    }
  };

  return (
    <div className="login-page">
      <div className="login">
        <img src={require('../../assets/images/lawdroid-voice-logo.png')} alt="Lawdroid Logo" className={classes.img_logo} />
        <form className={classes.container} noValidate autoComplete="off"
          onKeyDown={inputOnKeyDown}>
          {values.errorMessages &&
            values.errorMessages.map(
              (err, i) => !!err && <SimpleSnackbar key={i} message={err} />,
            )}
          <h2>Sign In</h2>
          <TextField
            error={values.usernameHasError}
            id="standard-name"
            placeholder="Email"
            className={classes.textField}
            value={values.username}
            onChange={e => {
              setValues({ ...values, username: e.target.value });
            }}
          />
          <TextField
            error={values.passwordHasError}
            id="standard-password"
            type="password"
            placeholder="Password"
            className={classes.textField}
            value={values.password}
            onChange={e => {
              setValues({ ...values, password: e.target.value });
            }}
          />
          <Button
            onClick={handleLogin}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {loading ? <CircularProgress style={{ width: '27px', height: '27px' }} /> : 'Sign In'}
          </Button>
          <div className="login-secondaryActions">
            <Button
              className={classes.button}
              component={AdapterLink}
              to="/forgot"
            >
              Forgot Password?
            </Button>
            <Button
              className={classes.button}
              component={AdapterLink}
              to="/signup"
            >
              Don't have an account yet? <b>Sign Up</b>
            </Button>
          </div>
          <p className="verify_link mt-sm-3 mt-0"><a href="/verify">Click here to verify your account with code.</a></p>
        </form>
      </div>
    </div>
  );
};
