import * as React from 'react';
import '../Chat/app.scss';

import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { AuthContext } from '../../contexts/AuthContext';

export const TermsConditions: React.FC = () => {
  const auth = React.useContext(AuthContext);

  return (
    <div>
	    <Header/>
	   	<div className="app-main">
			<div className="app-main-inner">
			  <Container
			    style={{
			      paddingTop: 30,
			      paddingBottom: 30,
			      flex: 1,
			    }}
			  >
			<h1 className="h2 border-custom">Terms of Service</h1>
			<p className="mt-4">Last updated: August 15, 2018</p>
			<p>These Terms of Service ("Terms", "Terms of Service") govern your relationship with https://voice.lawdroid.com website (the "Service") operated by LawDroid Ltd. ("us", "we", or "our").</p>
			<p>Please read these Terms of Service carefully before using the Service.</p>
			<p>Your access to and use of the Service is based on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
			<p>By accessing or using the Service you agree to be bound by these Terms and accept all legal consequences. If you do not agree to these terms and conditions, in whole or in part, please do not use the Service.</p>
			<h4>Subscriptions</h4>
			<p>Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>
			<p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or LawDroid Ltd. cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting LawDroid Ltd. customer support team.</p>
			<p>A valid payment method, including credit card, is required to process the payment for your Subscription. You shall provide LawDroid Ltd. with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize LawDroid Ltd. to charge all Subscription fees incurred through your account to any such payment instruments.</p>
			<p>Should automatic billing fail to occur for any reason, LawDroid Ltd. will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>
			<h4>Free Trial</h4>
			<p>LawDroid Ltd. may, at its sole discretion, offer a Subscription with a free trial for a limited period of time ("Free Trial").</p>
			<p>You may be required to enter your billing information in order to sign up for the Free Trial.</p>
			<p>If you do enter your billing information when signing up for the Free Trial, you will not be charged by LawDroid Ltd. until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.</p>
			<p>At any time and without notice, LawDroid Ltd. reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.</p>
			<h4>Fee Changes</h4>
			<p>LawDroid Ltd., in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>
			<p>LawDroid Ltd. will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>
			<p>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>
			<h4>Refunds</h4>
			<p>Except when required by law, paid Subscription fees are non-refundable.</p>
			<h4>Accounts</h4>
			<p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
			<p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</p>
			<p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
			<h4>Intellectual Property</h4>
			<p>The Service and all contents, including but not limited to text, images, graphics or code are the property of LawDroid Ltd. and are protected by copyright, trademarks, database and other intellectual property rights. You may display and copy, download or print portions of the material from the different areas of the Service only for your own non-commercial use. Any other use is strictly prohibited and may violate copyright, trademark and other laws. These Terms do not grant you a license to use any trademark of LawDroid Ltd. or its affiliates. You further agree not to use, change or delete any proprietary notices from materials downloaded from the Service.</p>
			<h4>Links To Other Web Sites</h4>
			<p>The Service may contain links to third-party web sites or services that are not owned or controlled by LawDroid Ltd..</p>
			<p>LawDroid Ltd. has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that LawDroid Ltd. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.</p>
			<p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>
			<h4>Termination</h4>
			<p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p>
			<p>All provisions of the Terms shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
			<p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>
			<h4>Indemnification</h4>
			<p>You agree to indemnify, defend and hold harmless LawDroid Ltd., its principals, officers, directors, representatives, employees, contractors, licensors, licensees, suppliers and agents, from and against any claims, losses, damages, losses, obligations, costs, actions or demands.</p>
			<p>These include but are not limited to: (a) legal and accounting fees resulting from your use of the Service; (b) your breach of any of these Terms; (c) anything you post on or upload to the Service; and (d) any activity related to your account. This includes any negligent or illegal conduct by you, any person or entity accessing the Service using your account whether such access is obtained via fraudulent or illegal means.</p>
			<h4>Limitation Of Liability</h4>
			<p>LawDroid Ltd., its directors, employees, partners, agents, suppliers, or affiliates, shall not be liable for any loss or damage, direct or indirect, incidental, special, consequential or punitive damages, including without limitation, economic loss, loss or damage to electronic media or data, goodwill, or other intangible losses, resulting from (i) your access to or use of the Service; (ii) your inability to access or use the Service; (iii) any conduct or content of any third-party on or related to the Service; (iiv) any content obtained from or through the Service; and (v) the unauthorized access to, use of or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other claim in law, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
			<h4>Disclaimer And Non-Waiver of Rights</h4>
			<p>LawDroid Ltd. makes no guarantees, representations or warranties of any kind as regards the website and associated technology. Any purportedly applicable warranties, terms and conditions are excluded, to the fullest extent permitted by law. Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance, except as provided for under the laws of any province in Canada. In such cases, the provincial law shall apply to the extent necessary.</p>
			<p>LawDroid Ltd. its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>
			<p>If you breach any of these Terms and LawDroid Ltd. chooses not to immediately act, or chooses not to act at all, LawDroid Ltd. will still be entitled to all rights and remedies at any later date, or in any other situation, where you breach these Terms. LawDroid Ltd. does not waive any of its rights. LawDroid Ltd. shall not be responsible for any purported breach of these Terms caused by circumstances beyond its control. A person who is not a party to these Terms shall have no rights of enforcement.</p>
			<p>You may not assign, sub-license or otherwise transfer any of your rights under these Terms.</p>
			<h4>Exclusions</h4>
			<p>As set out, above, some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you. Provincial laws of Canada may apply to certain products and service provided.</p>
			<h4>Governing Law</h4>
			<p>These Terms shall be governed by, and interpreted and enforced in accordance with, the laws in the Province of British Columbia and the laws of Canada, as applicable.</p>
			<p>If any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction, then any remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements, oral or otherwise, regarding the Service.</p>
			<h4>Changes</h4>
			<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
			<p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
			<h4>Contact Us</h4>
			<p>If you have any questions about these Terms, please contact us.</p>

		  </Container>
		</div>
	</div>
	<Footer/>
</div>
  );
};
