import * as React from 'react';
import '../Chat/app.scss';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';

export const Commands: React.FC = () => {
	return (
		<div>
			{/* <div className="app-main"> */}
			<div className="app-main-inner">
				<div className="voice-command px-3">
					<h1 className="h2 border-custom">Voice Commands</h1>
					<h4 className="mt-4">1. Notes / Dictation</h4>
					<p><b>Voice Command:</b> "Make a note" or "Take notes"</p>
					<p>LawDroid will transcribe your notes. Say "completed" when you are finished dictating.</p>
					<p>LawDroid will then ask you to give the notes a subject title. Say "completed" when you are finished naming the note.</p>
					<p>LawDroid will then ask you where you would like to save the notes. You can store them either in a Clio contact or matter record. If you're not sure of the matter name, you can search by client.</p>
					<p><b>Voice Command:</b> "Put notes in Matter 00001" or "Matter 00001" or "Matter Nirvana Industries" or "Contact Kurt Cobain" or "Client Johnny Lydon"</p>
					<p>If you give a Matter or Client name, LawDroid will provide you with a list of matching matters to choose from. Just tell LawDroid the number for your selection.</p>
					<p>The notes will be added to the matter or contact record in Clio.</p>
					<h4>2. Time Entries / Billing</h4>
					<p><b>Voice Command:</b> "Start timer"</p>
					<p>LawDroid will begin running a timer to track your time spent on a task. To stop the timer, simply say "stop timer," then provide the name of the matter to which you are billing the time, the billing rate and a description of the time billed. The time entry will be added to the matter's activities in Clio.</p>
					<h4>3. Calendar / Appointments</h4>
					<p><b>Voice Command:</b> "Create new appointment"</p>
					<p>LawDroid will guide you through a series of prompts to create a new entry for an appointment in your calendar.</p>

					<p><b>Voice Command:</b> "Edit appointment"</p>
					<p>LawDroid will show the three most recent calendar entries and you can select the appointment you want to update or ask for more options to see other entries.</p>
					<p><b>Voice Command:</b> "Schedule for today"</p>
					<p>LawDroid will either display a table with all of your calendar items for the day or indicate none if there are no appointments.</p>
					<h4>4. Tasks / To Dos</h4>
					<p><b>Voice Command:</b> "Create a task"</p>
					<p>LawDroid will guide you through a series of prompts to create a new task.</p>

					<p><b>Voice Command:</b> "Task for date or today"</p>
					<p>LawDroid will either display a table with all of your tasks for the day or indicate none if there are no tasks due.</p>
					<h4>5. Stop / Exit</h4>
					<p><b>Voice Command:</b>  "Stop" or "Exit"</p>
					<p>
						If you're ever stuck in the middle of something and you'd like it to stop, just say "Stop" or "Exit" and LawDroid will cancel what it was doing.
						</p>
				</div>
			</div>
		</div>
		// </div>
	);
};
