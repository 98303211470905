import * as React from 'react';
import '../Chat/app.scss';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import Form from 'react-bootstrap/Form';
import { AuthContext } from '../../contexts/AuthContext';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { DATA_API_URL } from '../../config';


interface IValueState {
  fulfillments: any[];
  noteFilter: any;
  calendarEntryFilter: any;
  timeEntryFilter: any;
  taskFilter: any;
}

export const Timeline: React.FC = () => {
  	const auth = React.useContext(AuthContext);
  	const accessToken = auth.jwtToken;

  	const [values, setValues] = React.useState<IValueState>({
	    fulfillments: [],
		noteFilter: true,
		calendarEntryFilter: true,
		timeEntryFilter: true,
		taskFilter: true
	  });

	const getTimeLineContent = async () => {
		fetch(DATA_API_URL+'/v1/fulfillments', {
		  headers: new Headers({
		    'Content-Type': 'application/json',
		     Authorization: `Bearer ${accessToken}`,
		  })
		})
		.then(response => response.json())
		.then(data => {
			setValues({...values , fulfillments: data.fulfillments.filter((e) =>
				((e.resource == 'note' && values.noteFilter)  || (e.resource == 'calendarEntry' && values.calendarEntryFilter) || (e.resource == 'timeEntry' && values.timeEntryFilter) || (e.resource == 'task' && values.taskFilter)))});


			/*setValues({
	          ...values,
	          fulfillments: data.fulfillments
	        });*/
		})
		.catch(error => {})
	};

	const Capitalize = (str) => {
	    return str.charAt(0).toUpperCase() + str.slice(1);
	}

	const getContactOrMatter = (result)  => {
	    if(result.data.contact) {
			return (
				<div className="infoBlock">
		      		<div className="infoTitle">Contact</div>
		      		<div className="infovalue">{result.data.contact.name}</div>
		      	</div>
		     );
	    } else {
	    	return (
	    		<div className="infoBlock">
		      		<div className="infoTitle">Matter</div>
		      		<div className="infovalue">{result.data.matter.description}</div>
		      	</div>
		    );
	    }
	}

  	React.useEffect(() => {
	    getTimeLineContent();
	}, [auth, values.noteFilter, values.calendarEntryFilter, values.timeEntryFilter, values.taskFilter]);

  	return (
	  	<div>
		    <Header/>
		   	<div className="app-main">
				<div className="app-main-inner">
				  <Container
				    style={{
				      paddingTop: 30,
				      paddingBottom: 30,
				      flex: 1,
				    }}
				  >
				  <div className="row border-custom">
				  	<div className="col p-0">
				  		<h1 className="h2">Timeline</h1>
				  	</div>
				  </div>
				  <div className="row">
				  	<div className="col p-0 mt-4 d-flex flex-wrap justify-content-end">
				  		<Form className="filter">
						    <div key='custom-inline-checkbox'>
						      <b className="mr-3">Filter Activity</b>
						      <Form.Check
						        custom
						        inline
						        label="Note"
						        checked={values.noteFilter}
						        onChange={e => {
									setValues({ ...values, noteFilter: !values.noteFilter });
								}}
						        id={`custom-inline-checkbox-1`}
						      />
						      <Form.Check
						        custom
						        inline
						        label="CalendarEntry"
						        checked={values.calendarEntryFilter}
						        id={`custom-inline-checkbox-2`}
						        onChange={e => {
									setValues({ ...values, calendarEntryFilter: !values.calendarEntryFilter });
								}}
						      />
						      <Form.Check
						        custom
						        inline
						        label="TimeEntry"
						        checked={values.timeEntryFilter}
						        id={`custom-inline-checkbox-3`}
						        onChange={e => {
									setValues({ ...values, timeEntryFilter: !values.timeEntryFilter });
								}}
						      />
						      <Form.Check
						        custom
						        inline
						        label="Task"
						        checked={values.taskFilter}
						        className="mr-0"
						        id={`custom-inline-checkbox-4`}
						        onChange={e => {
									setValues({ ...values, taskFilter: !values.taskFilter });
								}}
						      />
						    </div>
						</Form>
				  	</div>
				  </div>
				   	<div className="showTimeline">
				   		<Accordion className="mt-3 mb-5">
				   			{values.fulfillments.reverse().map(function(result, i) {
								if(result.resource === 'note'){
								 return (
								 	<Card key={i}>
									    <Accordion.Toggle as={Card.Header} eventKey={i.toString()}>
									      <div className="heading">{new Date(result.createdAt).toLocaleString()}<span>{Capitalize(result.resource)}</span></div>
									    </Accordion.Toggle>
									    <Accordion.Collapse eventKey={i.toString()}>
									      <Card.Body>
									      	<div className="infoBlock">
									      		<div className="infoTitle">Subject</div>
									      		<div className="infovalue">{result.data.subject}</div>
									      	</div>
									      	<div className="infoBlock">
									      		<div className="infoTitle">Detial</div>
									      		<div className="infovalue">{result.data.detail}</div>
									      	</div>
                    						{getContactOrMatter(result)}
									      	</Card.Body>
									    </Accordion.Collapse>
									  </Card>
									);
							    } else if(result.resource === 'calendarEntry') {
							      return (
								 	<Card  key={i}>
									    <Accordion.Toggle as={Card.Header} eventKey={i.toString()}>
									      <div className="heading">{new Date(result.createdAt).toLocaleString()}<span>{Capitalize(result.resource)}</span></div>
									    </Accordion.Toggle>
									    <Accordion.Collapse eventKey={i.toString()}>
									      <Card.Body>
									      	<div className="infoBlock">
									      		<div className="infoTitle">Summary</div>
									      		<div className="infovalue">{result.data.summary}</div>
									      	</div>
									      	<div className="infoBlock">
									      		<div className="infoTitle">Location</div>
									      		<div className="infovalue">{result.data.location}</div>
									      	</div>
									      	<div className="infoBlock">
									      		<div className="infoTitle">Start Time</div>
									      		<div className="infovalue">{new Date(result.data.start_at).toLocaleString()}</div>
									      	</div>
									      	<div className="infoBlock">
									      		<div className="infoTitle">End Time</div>
									      		<div className="infovalue">{new Date(result.data.end_at).toLocaleString()}</div>
									      	</div>
                    						{getContactOrMatter(result)}
									      </Card.Body>
									    </Accordion.Collapse>
									  </Card>
									);
							    } else {
							      return (
								 	<Card  key={i}>
									    <Accordion.Toggle as={Card.Header} eventKey={i.toString()}>
									       <div className="heading">{new Date(result.createdAt).toLocaleString()}<span>{Capitalize(result.resource)}</span></div>
									    </Accordion.Toggle>
									    <Accordion.Collapse eventKey={i.toString()}>
									      <Card.Body>
									      	<div className="infoBlock">
									      		<div className="infoTitle">Name</div>
									      		<div className="infovalue">{result.data.name}</div>
									      	</div>
									      	<div className="infoBlock">
									      		<div className="infoTitle">Description</div>
									      		<div className="infovalue">{result.data.description}</div>
									      	</div>
									      	<div className="infoBlock">
									      		<div className="infoTitle">Status</div>
									      		<div className="infovalue">{result.data.status}</div>
									      	</div>
                    						{getContactOrMatter(result)}
									      </Card.Body>
									    </Accordion.Collapse>
									  </Card>
									);
							    }
							})
							}

						</Accordion>
				   	</div>
				  </Container>
				</div>
			</div>
			<Footer/>
		</div>
  	);
};
