import * as React from 'react';
import '../Login/login.scss';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { RouteComponentProps, Redirect } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';
import { AdapterLink } from '../AdapterLink';

import SimpleSnackbar from '../SimpleSnackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    img_logo: {
      width: 350,
      marginBottom: 10,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 20,
    },
    menu: {
      width: 200,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: 20,
    },
    input: {
      display: 'none',
    },
  }),
);

interface IValueState {
  username: string;
  password: string;
  code: string;
  errorMessages: string[];
  usernameHasError: boolean;
  passwordHasError: boolean;
  codeHasError: boolean;
}

export const ResetNewPassword: React.FC<RouteComponentProps> = props => {
  const auth = React.useContext(AuthContext);
  const classes = useStyles();

  const [values, setValues] = React.useState<IValueState>({
    username: auth.usernameForVerification || '',
    code: '',
    password: '',
    usernameHasError: false,
    passwordHasError: false,
    codeHasError: false,
    errorMessages: [],
  });

  React.useEffect(() => {
    setValues({
      ...values,
      username: values.username || auth.usernameForVerification || '',
    });
  }, [auth.usernameForVerification]);

  const { from } = props.location.state || { from: { pathname: '/' } };

  if (auth.hasValidSession && !auth.requireVerification) {
    return <Redirect to={from} />;
  }

  if (auth.requireVerification && !auth.hasValidSession) {
    return <Redirect to={'/verify'} />;
  }

  const validateForm = (username: string, password: string, code: string) => {
    let err: string = '';
    if (!username && !password && !code) {
      err = 'Please enter email, password and code.';
    }
    if (!username && password && code) {
      err = 'Please enter email.';
    }
    if (username && !password && code) {
      err = 'Please enter password.';
    }
    if (username && password && !code) {
      err = 'Please enter code.';
    }

    setValues({
      ...values,
      errorMessages: [...values.errorMessages, err],
      usernameHasError: !username,
      passwordHasError: !password,
      codeHasError: !code
    });

    return !!username && !!password && !!code;
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {  // if "Enter"
      e.preventDefault();
      handleReset();
    }
  };
  const handleReset = async () => {
    try {
      const { username, password, code } = values;
      const inputIsValid = validateForm(username, password, code);

      if (inputIsValid) {
        setValues({
          ...values,
          errorMessages: [],
          usernameHasError: false,
          passwordHasError: false,
          codeHasError: false,
        });

        auth.forgotPassword && (await auth.forgotPassword(2, { username, password, code }));
        setValues({
          ...values,
          errorMessages: ['Password reset successfully.'],
        });
        props.history.push('/login');
      }
    } catch (error) {
      setValues({
        ...values,
        errorMessages: [error.message],
      });
    }
  };

  return (
    <div className="login-page">
      <div  className="login reset-password">
        <img src={require('../../assets/images/lawdroid-voice-logo.png')} alt="Lawdroid Logo" className={classes.img_logo}/>
        <form className={classes.container} noValidate autoComplete="off" onKeyDown={inputOnKeyDown}>
          {values.errorMessages &&
            values.errorMessages.map(
              (err, i) => !!err && <SimpleSnackbar key={i} message={err} />,
            )}
          <h2>Reset Password</h2>
          <TextField
            error={values.usernameHasError}
            id="standard-name"
            placeholder="Email"
            className={classes.textField}
            value={values.username}
            onChange={e => {
              setValues({ ...values, username: e.target.value });
            }}
          />
          <TextField
            error={values.codeHasError}
            id="standard-code"
            placeholder="Code"
            type="text"
            className={classes.textField}
            value={values.code}
            onChange={e => {
              setValues({ ...values, code: e.target.value });
            }}
          />
          <TextField
            error={values.passwordHasError}
            id="standard-password"
            placeholder="New Password"
            type="password"
            className={classes.textField}
            value={values.password}
            onChange={e => {
              setValues({ ...values, password: e.target.value });
            }}
          />
          <Button
            onClick={handleReset}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Reset New Password
          </Button>
          <div className="login-secondaryActions">
            <Button
              className={classes.button}
              component={AdapterLink}
              to="/Login"
            >
              Back to Sign In
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
