import * as React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import {
  createMuiTheme,
  SimplePaletteColorOptions,
  darken,
  lighten,
} from '@material-ui/core/styles';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthProvider } from '../../contexts/AuthContext';
import { ProtectedRoute } from '../ProtectedRoute';
import { Chat } from '../Chat';
import { Login } from '../Login';
import { SignUp } from '../SignUp';
import { ForgotPassword } from '../ForgotPassword';
import { ResetNewPassword } from '../ResetNewPassword';
import { VerifyAccount } from '../VerifyAccount';
import { Profile } from '../Profile';
import { Timeline } from '../Timeline';
import { Commands } from '../Commands';
import { TermsConditions } from '../TermsConditions';
import { PrivacyPolicy } from '../PrivacyPolicy';
import { EditProfile } from '../EditProfile';
import { OpenApp } from '../OpenApp';

import DocumentMeta from 'react-document-meta';
import Favicon from 'react-favicon';
import Spinner from 'react-bootstrap/Spinner';

const primary: SimplePaletteColorOptions = {
  light: lighten('#4fc3f5', 0.1),
  main: '#4fc3f5',
  dark: darken('#4fc3f5', 0.1),
  contrastText: '#fff',
};

const theme = createMuiTheme({
  palette: {
    primary,
  },
});

const meta = {
      title: 'LawDroid Voice | AI Voice Assistant for Lawyers',
      description: "LawDroid Voice is the world's first AI voice assistant tailored for the needs of the legal industry. It helps you get more done in less time by letting you use voice commands to control your software.",
      canonical: 'http://example.com/path/to/page',
      image: '../../assets/images/LawDroid_Voice_Logo.png',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'AI, lawyers, voice assistant, attorneys, legal tech, artificial intelligence, Siri, Alexa, voice control, voice commands, solicitors, barristers, voice automation'
        }
      }
    };

export const ATJBot: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
    <DocumentMeta {...meta}>
      <AuthProvider>
      <Favicon url={require('../../assets/images/LawDroid_Voice_Logo.png')} />
        <Router>
          <ProtectedRoute exact path="/" component={Chat} />
          <ProtectedRoute exact path="/openApp" component={OpenApp} />
          <ProtectedRoute exact path="/openApp/authSuccess" component={OpenAppStatus} />
          <ProtectedRoute exact path="/openApp/authFailure" component={OpenAppStatus} />
          <ProtectedRoute exact path="/openApp/deAuthSuccess" component={OpenAppStatus} />
          <ProtectedRoute exact path="/openApp/deAuthFailure" component={OpenAppStatus} />
          <ProtectedRoute exact path="/profile" component={Profile} />
          <ProtectedRoute exact path="/timeline" component={Timeline} />
          {/* <ProtectedRoute exact path="/commands" component={Commands} /> */}
          <ProtectedRoute exact path="/edit" component={EditProfile} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <ProtectedRoute exact path="/privacy" component={PrivacyPolicy} />
          <ProtectedRoute exact path="/terms" component={TermsConditions} />
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/reset" component={ResetNewPassword} />
          <Route path="/verify" component={VerifyAccount} />
        </Router>
      </AuthProvider>
      </DocumentMeta>
    </ThemeProvider>
  );
};

const OpenAppStatus: React.FC = () => {
  return(
    <Spinner animation="border" role="status" variant="primary" style={{ width: "6rem", height: "6rem", margin: 'auto'}}>
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
}