import * as React from 'react';
import '../Chat/app.scss';

import Button from 'react-bootstrap/Button'
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { AuthContext } from '../../contexts/AuthContext';
import CryptoJS from 'crypto-js';
import {BACKEND_API_URL, CLIO_CLIENT_ID, CRYPTO_KEY,APP_API_URL} from '../../config';

import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

interface IValueState {
  message: any;
  redirect_url: any;
}

export const OpenApp: React.FC = () => {
  const auth = React.useContext(AuthContext);

	const encryptText = (text: string) => {
    return CryptoJS.AES.encrypt(text, CRYPTO_KEY);
  };

  const decryptText = (text: string) => {
    return CryptoJS.AES.decrypt(text, CRYPTO_KEY);
  };

  const [show, setShow] = React.useState(false);

  const [values, setValues] = React.useState<IValueState>({
    message: '',
    redirect_url: ''
    });

  React.useEffect(() => {
  	let query_params = new URLSearchParams(window.location.search);
    let clioCode = query_params.get('code');
    let deAuth = query_params.get('deauth');

    if(deAuth) {
      fetch(BACKEND_API_URL+'clioDeauthorize', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: auth.user['attributes']['custom:clioAccessToken'] })
        })
      .then(res => res.json())
      .then(response => {
        if(response['msg'] === "Successful Response") {
          let clioId = '';
          let clioAccessToken = '';
          let clioRefreshToken = '';
          let clioTimestamp = '';

          auth.updateUserClio && (auth.updateUserClio({clioId, clioAccessToken, clioRefreshToken, clioTimestamp}));
          
          setTimeout(() => {
            setValues({ ...values, message: "Clio Deauthorized Successfully.", redirect_url: '/openApp/deAuthSuccess'});
            setShow(true);
          }, 500);
        } else {
          setValues({ ...values, message: response['error_description'], redirect_url: '/openApp/deAuthFailure'});
          setShow(true);
        }
      });
    } else {
      if(clioCode == undefined || clioCode == null) {
        window.location.href = "https://app.clio.com/oauth/authorize?response_type=code&client_id="+CLIO_CLIENT_ID+"&redirect_uri="+APP_API_URL+"/openApp";
      } else if(clioCode && clioCode != null && auth.user['attributes']['custom:clioAccessToken'] == undefined) {
        fetch(BACKEND_API_URL+'clioAuthorize?code='+clioCode+'&reqFrom=LawdroidApp')
        .then(response => response.json())
        .then(res => {
          if(!res['error']) {
            let clioId = (res['userData']['id']).toString();
            let clioAccessToken = encryptText(res['access_token']).toString();
            let clioRefreshToken = encryptText(res['refresh_token']).toString();
            let clioTimestamp = ''+ res['timestamp'];
            // let clioLastUpdatedAt = ''+Date.now();

            auth.updateUserClio && (auth.updateUserClio({clioId, clioAccessToken, clioRefreshToken, clioTimestamp}));

            setTimeout(() => {
              setValues({ ...values, message: "Clio Authorized Successfully.", redirect_url: '/openApp/authSuccess'});
              setShow(true);
            }, 500);
          } else {
            setValues({ ...values, message: res['error_description'], redirect_url: '/openApp/authFailure'});
            setShow(true);
          }
        });
      } else if(auth.user['attributes']['custom:clioAccessToken'] != undefined) {
        setValues({ ...values, message: "Clio Already Authorized", redirect_url: '/openApp/authSuccess'});
        setShow(true);
      }
    }
  }, [auth.user['attributes']['email']]);

  const closeModal = () => {
    setShow(false);
    window.location.href = values.redirect_url;
  }

  return (
    <div style={{
            margin: 'auto'
          }}>
        <Alert show={show} variant="success" style={{
                    width: '100%'
                  }}>
          <Alert.Heading>Lawdroid Voice</Alert.Heading>
          <p>
            {values.message}
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() => closeModal()} variant="outline-success">
              Ok
            </Button>
          </div>
        </Alert>

        { !show ? 
          <Spinner animation="border" role="status" variant="primary" style={{ width: "6rem", height: "6rem"}}>
            <span className="sr-only">Loading...</span>
          </Spinner>
          :
          ''
        }
    </div>
  );
};
