import * as React from 'react';

import { AuthContext } from '../../contexts/AuthContext';
import { Route, Redirect } from 'react-router-dom';

// const ProtectedRoute: React.FC<RouteComponentProps> = ({
export const ProtectedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const auth = React.useContext(AuthContext);
  // console.log(auth);
  
  // const [isLoading, setIsLoading] = React.useState<boolean>(true);
  // const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);

  // React.useEffect(() => {
  //   const verifySession = async () => {
  //     const isValidSession =
  //       !!auth.isAuthenticated && (await auth.isAuthenticated());
  //     setIsLoggedIn(!!isValidSession);
  //     setIsLoading(false);
  //   };

  //   if (isLoading) {
  //     verifySession();
  //   }
  // }, []);

  // console.log('isLoading', isLoading);
  // console.log('isLoggedIn', isLoggedIn);

  // if (isLoading) {
  //   return (
  //     <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
  //       <p>Loading</p>
  //     </div>
  //   );
  // }
  return (
    <Route
      {...rest}
      render={props =>
        auth.hasValidSession ? (
          (!auth.user['attributes']['custom:formCompleted'] && window.location.pathname != '/edit' && window.location.pathname != '/openApp') ? 
          (<Redirect
            to={{
              pathname: '/edit',
              state: { from: props.location },
            }}
          />) : (
          <Component {...props} />
        )) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
